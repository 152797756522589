import React, {useCallback} from "react";
import {darken} from "polished";
import {connectModal} from "redux-modal";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {SOLUTIONS} from "constants/profile";
import {Modal} from "components/Modal";

const StartHelperModal = ({start, handleHide}) => {
  const profile = useSelector((state) => state.user.profile);
  const showProUserDescription = profile.default_account?.solution === SOLUTIONS.PRO;

  const onClick = useCallback(() => {
    handleHide();
    start(true);
  }, [handleHide, start]);

  return (
    <Modal
      onClose={() => null}
      noFooter
      width={395}
      customHeader={null}
      disableHandleHide
    >
      <Container>
        <Title>
          Now You're <span>Registered</span>!
        </Title>
        {showProUserDescription ? (
          <Description>
            Here's some cool ways
            <br />
            to use iFOLIO <span>Pro</span>.
          </Description>
        ) : (
          <Description>
            Let's take a quick tour of
            <br />
            how to use <span>iFOLIO</span>.
          </Description>
        )}
        <Button onClick={onClick}>LET'S GO!</Button>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratBold};
  color: ${({theme}) => theme.colors.dark};
  text-align: center;

  span {
    color: ${({theme}) => theme.colors.accent};
  }
`;

const Description = styled.div`
  font-size: 17px;
  line-height: 2;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratNormal};
  text-align: center;
  margin: 15px 0 30px 0;

  span {
    color: ${({theme}) => theme.colors.accent};
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratSemibold};
  color: ${({theme}) => theme.colors.main};
  background: ${({theme}) => theme.colors.accent};
  height: 42px;
  border-radius: 5px;
  font-size: 13px;
  width: 150px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => darken(0.1, theme.colors.accent)};
  }
`;

export default connectModal({name: "helper-start-modal"})(StartHelperModal);
